module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sana Beer Co.","short_name":"Sana","start_url":"/","background_color":"##ffff00","theme_color":"#ffff00","display":"standalone","icon":"src/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0132c4f8d9a43c30a3efd24d26a74bd2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"sana","accessToken":"MC5ZZlFhX2hFQUFDNEFJNzg2.Ju-_vQMBU3_vv71177-9Je-_vS3vv73vv70_77-9cxnvv73vv73vv73vv73vv70UYzjvv71_77-9Bg3vv70","promptForAccessToken":true,"apiEndpoint":"https://sana.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.3.1","publicApiKey":"YmE2MWI0YjEtMTUyNC00NGIyLTk0NjMtMzE3OGIzOWQ3YjZhNjM3Nzk1Njc2OTIyNTM1NDY5","defaultLang":"en","currency":"gbp","openCartOnAdd":false,"useSideCart":false,"innerHTML":"\n        <card-product>\n            <div class=\"snipcart-card-product\">\n            <img\n                :src=\"image\"\n                :alt=\"name\"\n                class=\"snipcart-card-product__image\"\n            />\n            <div class=\"snipcart-card-product__infos\">\n                <div class=\"snipcart-card-product__name\">{{ name }}</div>\n                <div class=\"snipcart-card-product__price-ctn\">\n                    <div class=\"snipcart-card-product__quantity\">{{ quantity }}</div>\n                    <div class=\"snipcart-card-product__by\">×</div>\n                    <div class=\"snipcart-card-product__price\">{{ price | money(currency) }}</div>\n                </div>\n            </div>\n          </div>\n        </card-product>\n        "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
